<template>
  <div class="page-main-bg" id="propertiesPage">
    <v-container class="main-container position-relative">
      <!-------------Toolbar----------------------->
      <tool-bar v-bind="toolbarProps" />
      <v-card class="pa-16">
        <v-row>
          <v-col :cols="4" class="d-flex justify-center">
            <div>
              <div class="d-flex max-h-15">
                <v-img
                  max-width="233"
                  min-width="233"
                  max-height="210"
                  min-height="210"
                  class="mx-auto"
                  :src="downloadIcon"
                />
              </div>
              <div class="numberHolder">
                <p class="text-center font-16 font-weight-bold">۱</p>
              </div>

              <div class="textHolder mt-4">
                <p class="title text-center">تنزيل قالب البيانات</p>
                <p class="description text-center">
                  يجب تنزيل قالب البيانات بصيفة CSV لمعرفة البيانات والحقول
                  المطلوبة لتتم عملية إستيراد البيانات بنحاح
                </p>
              </div>

              <div class="actionHolder d-flex justify-center">
                <v-btn
                  :loading="downloadInProgress"
                  class="download"
                  outlined
                  rounded
                  @click="handleDownload"
                >
                  <div class="d-flex align-center mx-2">
                    <span class="ml-3 font-16">تنزيل</span>
                    <v-img width="18" :src="download" />
                  </div>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col :cols="4" class="d-flex justify-center">
            <div>
              <div class="d-flex max-h-15">
                <v-img
                  max-width="250"
                  min-width="250"
                  max-height="165"
                  min-height="165"
                  class="mx-auto"
                  :src="dataIcon"
                />
              </div>
              <div class="numberHolder">
                <p class="text-center font-16 font-weight-bold">٢</p>
              </div>

              <div class="textHolder mt-4">
                <p class="title text-center">إدخال البيانات في القالب</p>
                <p class="description text-center">
                  افتح القالب الذي تم تنزيله في تطبيق جدول البيانات مثل (Excel)
                  وادخل البيانات اسفل عناوين الأعمدة
                </p>
              </div>
            </div>
          </v-col>
          <v-col :cols="4" class="d-flex justify-center">
            <div>
              <div class="d-flex max-h-15">
                <v-img
                  max-width="250"
                  min-width="250"
                  max-height="165"
                  min-height="165"
                  class="mx-auto"
                  :src="uploadIcon"
                />
              </div>
              <div class="numberHolder">
                <p class="text-center font-16 font-weight-bold">٣</p>
              </div>

              <div class="textHolder mt-4">
                <p class="title text-center">تحميل البيانات</p>
                <p class="description text-center">
                  قم برفع القالب بعد التأكد من إدخال البيانات المطلوبة
                </p>
              </div>

              <div class="actionHolder d-flex justify-center">
                <v-btn
                  class="upload"
                  outlined
                  rounded
                  @click="changeDialogState(true)"
                >
                  <div class="d-flex align-center mx-2">
                    <span class="ml-3 font-16">تحميل</span>
                    <v-img width="18" :src="uploadIconArrow" />
                  </div>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <dataExportModal
        :dialogState="dialogState"
        :type="type"
        @changeDialogState="changeDialogState($event)"
        v-if="dialogState"
      />
    </v-container>
  </div>
</template>

<script>
import ToolBar from '@/components/listing/ToolBar'
import dataExportModal from '@/components/helper/dataExportModal.vue'
import { userService } from '../services'

export default {
  name: 'exportData',
  components: { ToolBar, dataExportModal },
  data: () => {
    return {
      type: '',
      dialogState: false,
      downloadIcon: require('@/assets/img/export-data/DownloadIcon.svg'),
      dataIcon: require('@/assets/img/export-data/DataIcon.svg'),
      uploadIcon: require('@/assets/img/export-data/UploadIcon.svg'),
      uploadIconArrow: require('@/assets/img/export-data/UploadIconArrow.svg'),
      download: require('@/assets/img/export-data/download.svg'),
      downloadInProgress: false
    }
  },
  methods: {
    changeDialogState(value) {
      this.dialogState = value
    },
    async handleDownload() {
      try {
        this.downloadInProgress = true
        const fileType = this.type == 'clients' ? this.type : 'realestates' // for conveting properties into realestates to match backEnd validation
        const res = await userService.downloadExcelSheet(fileType)
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${Date.now()}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (err) {
        console.log(err)
      } finally {
        this.downloadInProgress = false
      }
    }
  },
  computed: {
    pageType() {
      return this.type == 'clients' ? 'العملاء' : 'العقارات'
    },
    toolbarProps() {
      return {
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            link: true,
            text: `قائمة ${this.pageType}`,
            to: {
              path: `/dashboard/${this.type}`
            }
          },
          {
            text: `إستيراد البيانات`,
            disabled: true
          }
        ],
        title: 'إستيراد البيانات'
      }
    }
  },
  created() {
    const { type } = this.$route?.query || 'clients'
    this.type = type
  }
}
</script>

<style lang="scss" scoped>
@import '/src/styles/material-dashboard/_variables.scss';
.max-h-15 {
  max-height: 15.5vh;
  min-height: 15.5vh;
}

.numberHolder {
  margin: 100px 0 0 0;
  max-height: 4vh;
  min-height: 4vh;
  display: flex;
  p {
    background: rgba($primary-color, 0.07);
    border: 2px solid #efebf2;
    border-radius: 12px;
    margin: 0 auto;
    padding: 3px 12px 5px 11px;
    max-width: 50%;
    color: $primary-color;
  }
}

.textHolder {
  max-height: 14vh;
  min-height: 14vh;
  .title {
    color: #101010;
    font-size: 16px !important;
    font-weight: bold;
  }
  .description {
    padding: 0 60px;
    color: #9f9fac;
    font-size: 14px !important;
    font-weight: bold;
  }
}
.actionHolder {
  .v-btn {
    border: 1px solid $primary-color;
    border-radius: 7px;
  }
  .download {
    background: #fff;
    span {
      color: $primary-color;
    }
  }

  .upload {
    background: $primary-color;
    span {
      color: #fff;
    }
  }
}
</style>
